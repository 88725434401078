#outlinedBasicTime{
    margin-left: 20px;
    width: 250px;
}

#outlined-name{
    width: 215px !important;
}

#inputLabelUser{
    width: 205px !important;
}