#outlinedBasic{
  height:2px;
}
#tabBor{
  border: 1px solid #000;
  padding: 1.5px !important;
}

#tabFooterPage{
  width: 96%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 10px 0px;
}

.dicefn{
  display: flex;
  flex-direction: column;
  line-height: 1.5;
}

#notDataBody{
 width: 100%;
 text-align: center;
 display: flex;
 justify-content: center;
 height: 50px;
 line-height: 50px;
 font-weight: bold;
 font-size: 18px;
}

#flagdic{
  margin-top: 20px;
  margin-left: 10px;
}

#flagdic .ant-picker{
  height: 40px;
}

.MuiInput-underline:before{
  border: none !important;
}

#padi{
  margin-bottom: 20px;
  padding: 0px 1%;
}

#slicesclass{
  overflow: auto !important;
}